import { UploadOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Form, Input, Space, Upload } from 'antd';
import { isArray } from 'lodash';
import React, { useEffect, useState } from 'react';
import { ASSET_CATEGORY, MODULES, ROUTES } from '../../../common/constants';
import {
  formValidatorRules,
  getBase64,
  videoUpload
} from '../../../common/utils';
import LoaderComponent from '../../../components/LoaderComponent';
import PageHeader from '../../../components/PageHeader';
import PreviewModal from '../../../components/PreviewModal';
import ProgressBar from '../../../components/ProgressBar';
import history from '../../../historyData';
import { Switch } from '../../pages/component/pageModules/moduleForms/FormInputs';
import { Select } from '../../videos/components/FormInputs';
import {
  CREATE_VIDEO_ASSET,
  UPDATE_VIDEO_ASSET,
  UPDATE_VIDEO_ASSET_STATUS
} from '../graphql/Mutations';
import { GET_ASSET, GET_LANGUAGES } from '../graphql/Queries';

const { TextArea } = Input;

const languageVariableSelector = ({ skip, limit, search }) => ({
  filter: {
    skip,
    limit,
    search
  }
});

export const SelectLanguage = ({ ...rest }) => {
  return (
    <Select
      popupMatchSelectWidth={false}
      variablesSelector={languageVariableSelector}
      placeholder="Select language"
      query={GET_LANGUAGES}
      dataSelector={(data) =>
        data?.languages?.languages?.map(({ code, name }) => ({
          label: name,
          value: code
        })) ?? []
      }
      keys={{
        data: 'languages',
        records: 'languages',
        count: 'count'
      }}
      queryOptions={{
        fetchPolicy: 'cache-first'
      }}
      {...rest}
    />
  );
};

const AddEditVideo = (props) => {
  const {
    match: { params }
  } = props;
  const { id: videoId } = params;
  const isUpdate = !!videoId;
  const [form] = Form?.useForm();
  const isVideoUrl = Form?.useWatch('isVideoUrl', form) ?? false;
  const isLive = Form?.useWatch('isLive', form) ?? false;
  const [videoData, setVideoData] = useState({});
  const [videoLoading, setVideoLoading] = useState(true);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewVideo, setPreviewVideo] = useState();
  const [previewTitle, setPreviewTitle] = useState();
  const [buttonLoading, setButtonLoading] = useState(false);
  const [videoProgress, setVideoProgress] = useState(undefined);
  const [fetchVideo] = useLazyQuery(GET_ASSET, {
    variables: { where: { id: videoId } },
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      setVideoLoading(true);
      setVideoData({
        ...res?.asset,
        ...(res?.asset?.isLive && { liveUrl: res?.asset?.url }),
        languageCode: res?.asset?.language
          ? {
              value: res?.asset?.language?.code,
              label: res?.asset?.language?.name
            }
          : null
      });
      setVideoLoading(false);
    },
    onError: () => {
      setVideoLoading(false);
    }
  });

  useEffect(() => {
    if (videoId) {
      fetchVideo();
    } else {
      setVideoLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoId]);

  const [createVideoAssetMutate] = useMutation(CREATE_VIDEO_ASSET, {
    onError() {
      setButtonLoading(false);
    }
  });
  const [updateVideoAssetMutate] = useMutation(UPDATE_VIDEO_ASSET, {
    onError() {
      setButtonLoading(false);
    }
  });
  const [updateVideoAssetStatus] = useMutation(UPDATE_VIDEO_ASSET_STATUS);

  const handlePreview = async (file) => {
    let preview;
    if (!file?.url && !file?.preview) {
      preview = await getBase64(file?.originFileObj);
    }
    setPreviewVisible(true);
    setPreviewTitle(
      file?.name ||
        file?.label ||
        file?.url?.substring(file?.url?.lastIndexOf('/') + 1)
    );
    setPreviewVideo(file?.url || preview);
  };

  const onFinish = async (values) => {
    setButtonLoading(true);
    const { video } = values;
    try {
      const textData = {
        title: values?.title,
        description: values?.description,
        languageCode: values?.languageCode?.value ?? null
      };
      const videoFile = video?.[0]?.originFileObj;
      const isLiveOn = values?.isLive || false;
      if (isUpdate) {
        updateVideoAssetMutate({
          variables: {
            where: { id: videoId },
            data: { ...textData, ...(isLiveOn && { url: values?.liveUrl }) }
          }
        })
          .then(() => {
            history?.replace(ROUTES?.VIDEOS);
            setButtonLoading(false);
          })
          .catch(() => {
            setVideoProgress(undefined);
            setButtonLoading(false);
          });
      } else {
        const isImportUrl = values?.isVideoUrl;
        const response = await createVideoAssetMutate({
          variables: {
            data: {
              ...textData,
              ...(isImportUrl && !isLiveOn && { importUrl: values?.videoUrl }),
              ...(videoFile &&
                !isLiveOn && {
                  contentLength: videoFile?.size,
                  fileName: `${videoFile?.name?.replace(/\s/g, '_')}`
                }),
              isLive: isLiveOn,
              ...(isLiveOn && { liveUrl: values?.liveUrl })
            }
          }
        });
        if (isImportUrl || isLiveOn) {
          setVideoProgress(undefined);
          setButtonLoading(false);
          history?.replace(ROUTES?.VIDEOS);
          return;
        }
        setVideoProgress(0);
        if (response?.data?.createVideoAsset?.signedUrl) {
          const res = await videoUpload(
            response?.data?.createVideoAsset?.signedUrl,
            videoFile,
            setVideoProgress
          );
          if (res && response?.data?.createVideoAsset?.asset?.id) {
            const data = JSON.parse(res);

            updateVideoAssetStatus({
              variables: {
                peertubeUUID: data.video?.uuid,
                id: response?.data?.createVideoAsset?.asset?.id
              }
            })
              .then(() => {
                history?.replace(ROUTES?.VIDEOS);
                setVideoProgress(undefined);
                setButtonLoading(false);
              })
              .catch(() => {
                setVideoProgress(undefined);
                setButtonLoading(false);
              });
          }
        }
      }
    } catch (error) {
      setVideoProgress(undefined);
      setButtonLoading(false);
      return error;
    }
  };

  const handleCancel = () => {
    history?.replace(ROUTES?.VIDEOS);
  };

  const initialValues = {
    ...videoData,
    isVideoUrl: false,
    videoUrl: ''
  };

  if (videoLoading) {
    return <LoaderComponent />;
  }
  return (
    <>
      <PreviewModal
        previewData={previewVideo}
        previewTitle={previewTitle}
        previewVisible={previewVisible}
        setPreviewVisible={setPreviewVisible}
        previewType={ASSET_CATEGORY?.VIDEO}
      />
      <PageHeader menu={MODULES?.ASSETS} />
      <div className="page-wrapper">
        <div className="page-wrapper-body">
          <Form
            form={form}
            initialValues={initialValues || { isActive: true }}
            name="create-asset"
            layout="vertical"
            className="add-edit-form"
            onFinish={onFinish}
          >
            <Form.Item
              name="title"
              label="Title"
              required
              rules={[
                formValidatorRules?.required('Please enter title!'),
                formValidatorRules?.maxLength(200, 'Max 200 characters allowed')
              ]}
            >
              <Input placeholder="Enter title" disabled={buttonLoading} />
            </Form.Item>

            <Form.Item name="description" label="Description">
              <TextArea
                rows={2}
                placeholder="Enter description"
                disabled={buttonLoading}
              />
            </Form.Item>
            <Form.Item
              name="languageCode"
              label="Language Code"
              rules={[
                {
                  required: true,
                  message: 'Please select language!'
                }
              ]}
            >
              <SelectLanguage />
            </Form.Item>

            {!isUpdate && (
              <>
                <Form.Item name="isVideoUrl" valuePropName="checked">
                  <Switch
                    label="Do you have video URL?"
                    onChange={() => form?.resetFields(['isLive', 'liveUrl'])}
                  />
                </Form.Item>

                {!isVideoUrl ? (
                  <Form.Item
                    name="video"
                    label="Video"
                    rules={[
                      {
                        required: !isLive,
                        message: 'Please select video!'
                      }
                    ]}
                    getValueFromEvent={(e) => {
                      if (isArray(e)) {
                        return e;
                      }
                      return e?.fileList;
                    }}
                    valuePropName="fileList"
                  >
                    <Upload
                      maxCount={1}
                      disabled={buttonLoading || isLive}
                      onPreview={handlePreview}
                      accept=".mp4"
                      beforeUpload={() => false}
                    >
                      <Space>
                        <Button icon={<UploadOutlined />}>
                          Click to Upload
                        </Button>
                        <span>(.mp4)</span>
                      </Space>
                    </Upload>
                  </Form.Item>
                ) : (
                  <Form.Item
                    name="videoUrl"
                    label="URL"
                    rules={[{ required: true, message: 'Please enter URL!' }]}
                  >
                    <Input placeholder="Enter url" disabled={buttonLoading} />
                  </Form.Item>
                )}
              </>
            )}
            <Form.Item name="isLive" valuePropName="checked">
              <Switch
                label="Is this a livestream Video asset?"
                disabled={videoData?.isLive}
                onChange={() =>
                  form?.resetFields(['isVideoUrl', 'video', 'videoUrl'])
                }
              />
            </Form.Item>
            {isLive && (
              <Form.Item
                name="liveUrl"
                label={`Live Stream HLS URL "M3U8"`}
                rules={[{ required: true, message: 'Please enter URL!' }]}
              >
                <Input
                  placeholder="Enter url"
                  disabled={buttonLoading}
                  onChange={() =>
                    form?.resetFields(['isVideoUrl', 'video', 'videoUrl'])
                  }
                />
              </Form.Item>
            )}
            {videoProgress >= 0 && (
              <Form.Item>
                <ProgressBar progress={videoProgress} />
              </Form.Item>
            )}

            <div className="d-flex button-section">
              <Space>
                <Form.Item>
                  <Button
                    loading={buttonLoading}
                    type="text"
                    htmlType="submit"
                    className="text-btn mr-8"
                    size="middle"
                  >
                    Save
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Button
                    type="text"
                    className="text-btn2"
                    disabled={buttonLoading}
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                </Form.Item>
              </Space>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default AddEditVideo;
