import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Form, Select, Space } from 'antd';
import { isArray } from 'lodash';
import { FileArrowDown } from 'phosphor-react';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  EXPORT_JOB_OPTIONS,
  EXPORT_JOB_TYPES,
  IMPORT_JOBS_STATIC_FILES_URL,
  MODULES,
  ROUTES
} from '../../common/constants';
import { fileUpload } from '../../common/utils';
import PageHeader from '../../components/PageHeader';
import ProgressBar from '../../components/ProgressBar';
import { FileUpload } from '../assets/icons/components';
import { CREATE_IMPORT_JOB } from './graphql/Mutations';
import { IMPORT_JOB_SIGNED_URL } from './graphql/Queries';

const initialValues = {
  type: EXPORT_JOB_TYPES.USER,
  csv: []
};

const AddImportJob = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const showProgress = progress !== 0 && progress !== 100;

  const type = Form.useWatch(['type'], form);

  const [getSignedUrl] = useLazyQuery(IMPORT_JOB_SIGNED_URL, {
    fetchPolicy: 'network-only'
  });

  const [createJob] = useMutation(CREATE_IMPORT_JOB);

  const history = useHistory();

  const handleCancel = () => {
    history.push(ROUTES?.IMPORT_JOBS);
  };

  const handleSubmit = async ({ csv }) => {
    setLoading(true);
    const payload = { type, key: '', uid: '' };
    try {
      if (csv?.length) {
        const file = csv?.[0]?.originFileObj;

        const { data, error } = await getSignedUrl({
          variables: {
            data: {
              type
            }
          }
        });
        if (error) {
          throw new Error(error);
        }
        if (data?.getImportJobCsvUploadSignedUrl) {
          await fileUpload(
            data?.getImportJobCsvUploadSignedUrl?.signedUrl,
            file,
            setProgress
          );
          payload.key = data?.getImportJobCsvUploadSignedUrl?.key;
          payload.uid = data?.getImportJobCsvUploadSignedUrl?.uid;

          const { errors } = await createJob({
            variables: {
              data: payload
            }
          });
          if (errors) {
            throw new Error(errors);
          }
          history.push(ROUTES?.IMPORT_JOBS);
        }
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  return (
    <>
      <PageHeader menu={MODULES?.IMPORT_JOBS} />
      <div className="page-wrapper">
        <div className="page-wrapper-body">
          <Form
            form={form}
            onFinish={handleSubmit}
            name="create-asset"
            layout="vertical"
            className="add-edit-form"
            initialValues={initialValues}
          >
            <Form.Item
              rules={[{ required: true, message: 'Please select type!' }]}
              name="type"
              label="Type"
            >
              <Select
                placeholder="Select type"
                options={EXPORT_JOB_OPTIONS.filter(
                  ({ value }) => value !== EXPORT_JOB_TYPES.DONATION
                )}
              />
            </Form.Item>
            <Form.Item
              name="csv"
              label="File"
              rules={[{ required: true, message: 'Please select file!' }]}
              getValueFromEvent={(e) => {
                if (isArray(e)) {
                  return e;
                }
                return e?.fileList;
              }}
              valuePropName="fileList"
              extra={
                <a
                  className="d-flex align-center mt-8"
                  download
                  href={`${process.env.REACT_APP_STATIC_DATA_URL}/static/import-jobs/samples/${IMPORT_JOBS_STATIC_FILES_URL[type]}`}
                >
                  Sample file <FileArrowDown className="ml-8" size={22} />
                </a>
              }
            >
              <FileUpload maxCount={1} accept=".csv" helperText=".csv" />
            </Form.Item>

            {showProgress && (
              <Form.Item>
                <ProgressBar progress={progress} />
              </Form.Item>
            )}
            <div className="d-flex button-section">
              <Space>
                <Form.Item>
                  <Button
                    loading={loading}
                    type="text"
                    htmlType="submit"
                    className="text-btn mr-8"
                    size="middle"
                  >
                    Save
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Button
                    type="text"
                    className="text-btn2"
                    disabled={loading}
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                </Form.Item>
              </Space>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default AddImportJob;
